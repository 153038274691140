import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useNavigate, useMatch } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import DownloadfileModal from "../components/common/downloadfileModal";

type data = {
  _id: string,
  profile_pic: string,
  name: string,
  created_at: string,
  phone_no: number,
  email: string,
  document_status: string,
  country: string

}
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  let limit = 10;
  const { authState, handleFilter, handlePagination, handleError } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageData, setPageData] = useState<Array<data>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);

  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
  const match = useMatch("/users/:page")
  const downloadCsv = async (start_date: number, end_date: number) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.User.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        // timezone
      );
      let data = apiRes.data.data;
      console.log(data);

      const rows = [
        [
          "Sr No.",
          "Name",
          "Email",
          "Date",
          "phone_no",

        ],
      ];
      if (Array.isArray(data)) {
        data.map((res: any, index: number) => {
          rows.push([
            index + 1,
            res.name,
            res.email,
            moment(Number(res.created_at)).format("MMM DD YYYY"),
            res.phone_no

          ]);
        });
      }
      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `user_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error: any) { }
  };
  let q = newParam.toString()
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      // setSearchData(searchData);
      try {

        let apiRes = await henceforthApi.User.getListing(
          q,
          Number(params.page) - 1,
          limit
        );
        console.log(apiRes, "api res");

        setTotalCount(apiRes.data.total_count);
        setPageData(apiRes.data.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setPageData([]);
        handleError(error)
        setTotalCount(0);
      }
    };
    init();

  }, [params.page, newParam.get("search"), newParam.get("document_verified"),newParam.get("country")]);


  const handleUserFilter = (name: string, value: string) => {
    if (value) {
      newParam.set(name, value)
    } else {
      newParam.delete(name)
    }
    navigate({ pathname: "/users/1", search: newParam.toString() })

  }
  const handleUserFilter2 = (name: string, value: string) => {
    if (value) {
      newParam.set(name, value)
    } else {
      newParam.delete(name)
    }
    navigate({ pathname: "/users/1", search: newParam.toString() })

  }
  const filters: any = [
    { value: '', name: "ALL", id: 1 },
    { value: '0', name: "PENDING", id: 2 },
    { value: '1', name: "REJECTED", id: 3 },
    { value: '2', name: "ACCEPTED", id: 4 },
    { value: '3', name: "NOT_VERIFIED", id: 5 },
  ]
  const countryFilters: any = [
    { value: '', name: "ALL", id: 1 },
    { value: 'CA', name: "CA", id: 2 },
    { value: 'US', name: "US", id: 3 },
    { value: 'NG', name: "NG", id: 4 },
  ]


  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Users</h5>
          <div className="div d-flex ">
            <div className="dropdown theme me-3">
              <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown " data-bs-toggle="dropdown"
                aria-expanded="false">{newParam.has('document_verified') ? filters.find((res: any) => res.value == newParam.get("document_verified"))?.name : "All"}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "9px" }}></i></button>
              <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                {filters.map((res: any) => {
                  return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleUserFilter("document_verified", res.value)}>{res.name}</a></li>
                })}
              </ul>
            </div>
            {/* <div className="dropdown theme me-3">
              <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown1 " data-bs-toggle="dropdown"
                aria-expanded="false">{newParam.has('country') ? countryFilters.find((res: any) => res.value == newParam.get("country"))?.name : "All"}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "9px" }}></i></button>
              <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown1">
                {countryFilters.map((res: any) => {
                  return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleUserFilter2("country", res.value)}>{res.name}</a></li>
                })}
              </ul>
            </div> */}
            <button
              className="btn btn-primary-button mb-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Export
            </button>
          </div>
        </div>
        <div className="ibox-content">
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="input-group form-group">
              <input
                name="search"
                // onKeyPress="searchRegex"
                onChange={(e: any) => {
                  handleFilter(e.target.name, e.target.value);
                }}
                placeholder="Search by Name or Email"
                type="text"
                className="form-control form-control-sm"
              />{" "}
              <span className="input-group-append">

              </span>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  {/* <th>Name</th> */}
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Country</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {
                !loading &&
                <tbody>
                  {Array.isArray(pageData) && pageData.length > 0 ?
                    pageData.map((res: data, index: number) => {
                      return (
                        <tr key={res._id}>
                          <td>
                            {params.page === "0"
                              ? index + 1
                              : (params.page - 1) * limit + (index + 1)}.
                          </td>
                          <td>
                            <div className="text-nowrap">
                              <img
                                src={
                                  res?.profile_pic !== 'null' && res?.profile_pic !== null ? henceforthApi.FILES.imageOriginal(res?.profile_pic) : profile_placeholder
                                }
                                className="me-2 imageSize" alt="" />
                              {res.name ? `${res.name} ` : `Not Available`}
                            </div>
                          </td>
                          {/* <td onClick={() => { navigator?.clipboard?.writeText(res?.email).then(() => toast.success("Copied")).catch(() => toast.error("error")) }}> */}
                          <td>
                            {res.email
                              ? res.email
                              : "Not available"}
                          </td>
                          <td>{res.phone_no == 0 ? "Not Available" : res.phone_no}</td>
                          <td>{res.country ? res?.country : "Not Available"}</td>
                          <td>{res.created_at ? moment(Number(res.created_at)).format("MMM DD, YYYY") : 'N/A'}</td>
                          <td>{res.document_status ?? 'N/A'}</td>
                          <td>
                            <div className="d-flex">
                              <Link
                                to={`/user/${res._id}`}
                                className="btn btn-xs btn-white me-2"
                              >
                                <i className="fas fa-eye me-1"></i>
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    : <tr> <td colSpan={12} className="text-center">
                      Sorry! no data to display
                    </td> </tr>
                  }
                </tbody>
              }
            </table>
          </div>
          <PaginationLayout
            count={totalCount}
            data={pageData}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
      <DownloadfileModal data={downloadCsv} />
    </>
  );
};
