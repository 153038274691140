import React from 'react';
import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
interface driverDetail {
    name: string
}
interface ridebook {
    city_from: string
    booking_status: string
    city_to: string
    total_price: number
    booked_seats: number
    driver_detail: driverDetail
    id: string
}
function SavedRides({ _id }: any) {
    const [page, setPage] = useState<number>(1)
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        data: {
            data: [] as Array<ridebook>,
            total_count: 0
        }
    })
    const [loading, setLoading] = useState(false);
    const newParam = new URLSearchParams(location.search);
    let limit = 10;
    const { authState } = useContext(GlobalContext);
    const [details, setDetsails] = useState({} as any)
    if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
    const onChangePagination = (newVal: number) => {
        setPage(Number(newVal))
    };
    let q = newParam.toString()
    const getSavedRides = async () => {
        const bookDetail = await henceforthApi.User.savedRides(q,page - 1, limit)
        setState(bookDetail)
    }
    const showDetails = (res: any) => {
        setDetsails(res)
    }
    const filters: any = [
        { value: '', name: "ALL", id: 1 },
        { value: 'CA', name: "CA", id: 2 },
        { value: 'US', name: "US", id: 3 },
        { value: 'NG', name: "NG", id: 4 },
    ]

    const handleUserFilter = (name: string, value: string) => {
        if (value) {
            newParam.set(name, value)
        } else {
            newParam.delete(name)
        }
        console.log(newParam)
        navigate({ pathname: "/saved-rides/1", search: newParam.toString() })

    }

    useEffect(() => {
        getSavedRides()
    }, [page,newParam.get("country")])
    return (
        <div className='row'>
            <div className="ibox">
                <div className="ibox-title d-flex align-items-center justify-content-between">
                    <h5>Saved Rides</h5>
                    {/* <div className="div d-flex ">
                        <div className="dropdown theme me-3">
                            <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown " data-bs-toggle="dropdown"
                                aria-expanded="false">{newParam.has('country') ? filters.find((res: any) => res.value == newParam.get("country"))?.name : "All"}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "9px" }}></i></button>
                            <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                                {filters.map((res: any) => {
                                    return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleUserFilter("country", res.value)}>{res.name}</a></li>
                                })}
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="ibox-content">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                    <th>Pick up Location </th>
                                    <th>Drop off Location</th>
                                    <th>Date</th>
                                    <th>Phone No.</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            {state.data.total_count != 0 ?
                                <>
                                    <tbody>
                                        {state.data.data.map((res: any, index: number) => {
                                            return (
                                                <tr key={res.id} className='py-8' style={{ padding: '0px 20px' }}>

                                                    <td>
                                                        {page === 0
                                                            ? index + 1
                                                            : (page - 1) * limit + (index + 1)}
                                                        .
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            <img
                                                                src={
                                                                    res?.user_id?.profile_pic !== 'null' && res?.user_id?.profile_pic !== null ? henceforthApi.FILES.imageOriginal(res?.user_id?.profile_pic) : profile_placeholder
                                                                }
                                                                className="me-2 imageSize" alt="" />
                                                            {res?.user_id?.name ? `${res?.user_id?.name} ` : `Not Available`}
                                                        </div>
                                                        {/* <div className="text-nowrap">
                                                            {res?.user_id?.name
                                                                ? res?.user_id?.name : "Not available"}
                                                        </div> */}
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            {res?.user_id?.email
                                                                ? res?.user_id?.email : "Not available"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            {res?.user_id?.country
                                                                ? res?.user_id?.country : "Not available"}
                                                        </div>
                                                    </td>
                                                    <td title={res?.address_from}>
                                                        {res?.address_from?.length > 25 ? res?.address_from.slice(0, 25) + '...' : res?.address_from || "Not available"}
                                                    </td>
                                                    <td title={res?.address_to}>
                                                        {res?.address_to?.length > 25 ? res?.address_to.slice(0, 25) + '...' : res?.address_to || "Not available"}
                                                    </td>
                                                    <td>
                                                    {moment(Number(res.created_at)).format("MMM DD ,YYYY") ? moment(Number(res.created_at)).format("MMM DD, YYYY") : "Not available"}
                                                    </td>
                                                    <td>
                                                        {res?.user_id?.phone_no == 0 ? "Not available" : res?.user_id?.country_code + ' ' + res?.user_id?.phone_no}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div onClick={() => showDetails(res)}>
                                                                <button
                                                                    className="readmore btn btn-xs fw-bold"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#savedContent"
                                                                >
                                                                    <i className="fas fa-eye me-1"></i>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    {state.data?.total_count > 10 ?
                                        <div className="view-more mb-2">
                                            <div className="d-flex align-items-center justify-content-center">
                                                {/* <Link
                            to="/booked-rides/1"
                            className="btn btn-xs btn-white me-2"
                          >
                            View More
                          </Link> */}
                                            </div>
                                        </div> : ""
                                    }
                                </> :
                                <tbody >
                                    <td colSpan={6}>
                                        <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>
                                    </td>
                                </tbody>}
                        </table>
                        <PaginationLayout
                            count={state.data.total_count}
                            data={state.data.data}
                            page={page}
                            limit={Number(limit)}
                            loading={loading}
                            onPageChange={(val) => onChangePagination(val)}
                        />
                        <div className="view-more mb-2">
                            <div className="d-flex align-items-center justify-content-center">

                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="savedContent"
                            tabIndex={-1}
                            aria-labelledby="savedContentLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="m-0">Ride Details</h4>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='saved-ride-list gap-2 '>
                                            <div className='key'>Name</div>
                                            <div className='text-break value'>{details?.user_id?.name}</div>
                                        </div>
                                        <div className='saved-ride-list '>
                                            <div className='key'>Email </div>
                                            <div className='text-break value'>{details?.user_id?.email}</div>
                                        </div>
                                        <div className='saved-ride-list'>
                                            <div className='key'>Country </div>
                                            <div className='text-break value'>{details?.user_id?.country}</div>
                                        </div>
                                        <div className='saved-ride-list '>
                                            <div className='key'>Pick Up Location </div>
                                            <div className='text-break value'>{details?.address_from}</div>
                                        </div>
                                        <div className='saved-ride-list gap-2'>
                                            <div className='key'>Drop Off Location </div>
                                            <div className='text-break value'>{details?.address_to}</div>
                                        </div>
                                        <div className='saved-ride-list gap-'>
                                            <div className='key'>Date </div>
                                            <div className='text-break value'>{details?.date}</div>
                                        </div>
                                        <div className='saved-ride-list'>
                                            <div className='key'>Phone No.</div>
                                            <div className='text-break value'>{details?.user_id?.phone_no == 0 ? "Not available" : details?.user_id?.country_code + ' ' + details?.user_id?.phone_no}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedRides
